.header-hiddenable-notification-ul {
    overflow-y: scroll;
    height: 300px;
}

.header-hiddenable-notification-ul::-webkit-scrollbar {
    width: 0px;
}

.header-hiddenable-notification-ul::-webkit-scrollbar-track {
    background: transparent;
}

.header-hiddenable-notification-ul::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 20px;
    border: 3px solid transparent;
}