body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ececec !important;
  background: #ececec !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .table-container {
  margin: 15px;
} */

table {
  border-collapse: collapse;
}
th {
  background-color: #fff !important;
}
th,
td {
  width: 150px;
  text-align: center;
  padding: 5px;
  border-right: 0 solid transparent !important;
}
.table thead th {
  border-bottom: none;
}
.table th {
  padding: 10px;
}
.react-bs-container-body table {
  border-collapse: separate;
  border-spacing: 0px 12px;
}
tr.table-listing-tr {
  border-radius: 10px !important;
}
tr.table-listing-tr-salaries-table {
  cursor: pointer;
}
.table td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-left: 1px solid #ececec !important;
}
.table td {
  border-top: 1px solid #ececec !important;
  border-bottom: 1px solid #ececec !important;
}
.table td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-right: 1px solid #ececec !important;
}
/* Dans main.css ligne 278 */
.table td,
.table th {
  padding: 4px 0.75rem;
  vertical-align: middle;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
/* .table th:last-child {
    border-right: 1px solid #ececec !important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}
.table th:first-child {
    border-left: 1px solid #ececec !important;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
/* .makeStyles-instructions-24 {
    margin-top: 0 !important;
} */
.react-bs-container-header.table-header-wrapper table {
  background: #ececec;
}
.react-bs-container-header.table-header-wrapper {
  border-radius: 10px !important;
  border: 1px solid #ececec !important;
  padding: 6px;
}
.linkable {
  cursor: pointer !important;
}

a.nav-link.nav-link-vertival-custom.actived {
  background: rgba(255, 255, 255, 0.1);
  color: #fff !important;
  font-weight: 800;
  border-right: 5px solid #ffffff;
}
.chart-item-title {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: #252733;
}
.empty-suggestion {
  color: #ff0000;
  display: block;
  padding: 11px;
  font-weight: 600;
}
.video-viemo .player .vp-title .vp-title-header {
  display: none !important;
}
.error-row {
  width: 100%;
}
.date-counter-container {
  margin: 20px 0;
  color: #1f497d;
  font-weight: 600;
}
.start-date {
  font-weight: 600;
}
span.counter-item {
  margin-left: 18px;
  padding: 12px;
  background: #ececec;
  border-radius: 5px;
}
.goto-forum-item-btn {
  background: #1f497d;
  padding: 14px;
  border-radius: 50px;
  color: #fff;
  font-weight: 600;
  margin-top: 5px;
  display: block;
  text-align: center;
  border: 1px solid #1f497d;
}
.goto-forum-item-btn:hover {
  background: transparent;
  color: #1f497d;
  text-decoration: none;
}
.page-item.active .page-link {
  background-color: #356FC2 !important;
  border-color: #356FC2 !important;
  color: #fff !important;
  font-weight: 900;
}
li.page-item a {
  color: #1f497d !important;
}
.page-item:first-child .page-link span {
  color: #1f497d !important;
}
.page-item.disabled .page-link span {
  color: #1f497d !important;
}
.cm-sender-email {
  font-size: 15px;
  font-style: italic;
}
.badge-module-name {
  font-size: 20px;
  font-weight: 600;
  color: #1f497d;
}
.badge-blue {
  background: #1f497d !important;
}
.badge-orange {
  background: #f39200 !important;
}
.badge-green {
  background: #148f77 !important;
}
.theme-background-color {
  background-color: #1f497d !important;
}
.row.contact-support-row {
  margin-bottom: 15px;
}
.gfa-alert-info {
  background: #1f497d33 !important;
  border-color: #1f497d !important;
  color: #1f497d !important;
  text-align: justify !important;
}
.contact-support-link {
  color: #1f497d !important;
  font-weight: 800;
}

img.zoom-img {
  transform: scale(0.9) !important;
  z-index: 9999999999;
  width: 100%;
  /* margin-top: 30% !important; */
}
.image-zoomable-modal .modal-lg {
  min-width: 1000px !important;
}
.zoomTarget {
  cursor: zoom-in !important;
}
img.is-zoomed-image {
  width: 100%;
}
.regenerate-apprenant-password-icon {
  color: #fff !important;
  width: 16px;
}
.gfa-btn-action-primary {
  background: #1f497d !important;
  border-color: #1f497d !important;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gfa-btn-action-primary .regenerate-apprenant-password-icon:hover {
  background: transparent !important;
  border-color: #1f497d !important;
  color: #1f497d;
}
.actions-buttons-container {
  text-align: center;
}
button.btn.btn-success.react-bs-table-csv-btn.hidden-print {
  background: #f39200 !important;
  border-color: #f39200 !important;
  padding: 11px;
  margin-bottom: 9px;
  border-radius: 50px;
}
.user-infos-modal-col {
  border: 1px solid #ececec;
  padding: 15px 6px;
  border-radius: 10px;
  font-size: 18px;
  margin-bottom: 5px;
}
.user-infos-modal-item-label {
  font-weight: 600;
}
.user-infos-modal-item-content {
  color: #1f497d !important;
  font-weight: 700;
}
span.badge-success.gfa-badge-success,
span.badge-danger.gfa-badge-danger {
  padding: 7px;
  font-weight: 600;
  border-radius: 50px;
}
.title h5.quizz-label {
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 20px;
  text-align: initial !important;
}
.link-to-evaluation {
  background: #f39200;
  border-color: #f39200;
  margin-top: 10px;
  width: 100%;
  padding: 10px;
  border-radius: 25px !important;
  font-weight: 600;
}
.link-to-evaluation:hover {
  background: #fff;
  color: #f39200;
}
.no-identified-col .gfa-alert-danger {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}
/* div#g-recaptcha > div {
  width: 100% !important;
}
div#g-recaptcha {
  width: 100% !important;
}
div#g-recaptcha iframe {
  width: 100% !important;
}
div#rc-anchor-container {
  width: 99% !important;
  border-radius: 10px;
} */
.certified-count {
  color: #1f497d !important;
  font-weight: 700;
  font-size: 25px !important;
  display: inline-block;
}
b.orange-colored {
  color: #f39200 !important;
}
a.defaru-linking {
  font-weight: 800;
  color: #1f497d !important;
}
.gfa-image-container {
  height: 250px;
}
.center-paragraphe {
  text-align: center;
}
.gfa-blue-color {
  color: #1f497d !important;
  font-weight: 600 !important;
}
.pdf-created-link-container {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100% !important;
}
.pdf-created-link {
  width: 100% !important;
  background: #f39200;
  padding: 20px;
  color: #ffff;
  font-weight: 600;
  border-radius: 50px;
  text-decoration: initial;
  border: 1px solid #f39200 !important;
  transition: all 0.5s ease;
}
.pdf-created-link:hover {
  background: #fff !important;
  color: #f39200 !important;
}
.icon-eye {
  width: 16px;
}

/* ************************* New style */
body {
  background: #ececec;
}
.empty-fluidy {
  padding: 20px 125px;
  background: #fff !important;
}
.empty-header-speero {
  padding: 0 0 20px 0;
  background: #fff !important;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  z-index: 999;
}
.logo-item {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 47px;
  text-align: center;
  color: #000000;
}
.logo-secondy {
  font-weight: 800;
}
.login-page-row {
  margin-top: 3%;
  background: #fff;
  border-radius: 10px;
}
.left-side-col {
  background: #043b63 !important;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.login-form-container {
  padding: 35px 45px;
}
.login-title-label {
  font-size: 35px;
  color: #000;
  margin-bottom: 55px;
}
#login-form .input-group {
  border-bottom: 1px solid #ececec !important;
}
#login-form .input-with-icon,
#login-form .span-input-icon {
  background: transparent;
  border: none !important;
  border-radius: 0 !important;
}
.alling-header {
  margin-left: -15px;
  margin-right: -15px;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 5%);
}
.hiddenable-navlink-label {
  display: none;
}
.hoverable:hover span.hiddenable-navlink-label {
  display: inline-block;
}
/* .hoverable:hover .navbar-vertical-customisation {
  padding-top: 64px;
} */
.stat-conteny p {
  color: #000;
}
.parent-label-card-statistics {
  font-weight: 800;
}
.child-label-card-statistics {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
.label-stat-counter {
  font-size: 24px;
  font-weight: 600;
  color: #000;
  font-family: Montserrat;
}
.search-form-container {
  position: relative;
}
.search-form-content label {
  position: absolute;
  top: 20%;
  left: 4%;
}
input.form-control.search-input {
  width: 250px;
  border-radius: 8px;
}
.icon-statistic-1 {
  background: #356fc2;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  padding-top: 6px;
}
.icon-moving-color-1 {
  color: #356fc2;
}
.text-moving-color-1 {
  color: #356fc2 !important;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}
.icon-statistic-2 {
  background: #27ae60 !important;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  padding-top: 6px;
}
.icon-moving-color-2 {
  color: #27ae60;
}
.text-moving-color-2 {
  color: #27ae60 !important;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}
.icon-statistic-3 {
  background: #2d9cdb;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  padding-top: 6px;
}
.icon-moving-color-3 {
  color: #2d9cdb;
}
.text-moving-color-3 {
  color: #2d9cdb !important;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}
.headering {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header-others-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
}
.add-item-content svg {
  width: 48px;
  height: 48px;
}
.notification-item-content {
  position: relative;
}
.notification-counter {
  position: absolute;
  display: inline-block;
  top: -4px;
  width: 20px;
  height: 20px;
  background: red;
  text-align: center;
  color: #fff;
  font-weight: 700;
  border-radius: 50%;
  left: 50%;
}
img.user-avatar {
  border-radius: 8px;
}
svg.search-icony {
  width: 20px;
  height: 20px;
}
.navbar-vertical-customisation-v2:hover span.hiddenable-navlink-label {
  display: inline-block;
}
.page-content-container {
  background: #ffffff;
  border-radius: 4px;
  padding: 20px 30px;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 5%);
}
.page-first-row-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.page-title {
  font-size: 25px;
  color: #000;
  font-weight: 500;
}
.adding-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 12%;
}
.form-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table-filter-row.beneficiaire-filter {
  width: 40%;
}
.export-btn-container {
  width: 50%;
  text-align: right;
}
.export-btn {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border-color: transparent !important;
  width: 29%;
}
.export-btn-label {
  color: #000;
}
.entreprise {
  color: rgb(0 0 0 / 24%) !important;
}
.action-btn {
  background: transparent;
}
.table-actions-btn-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.breadcrumbs-and-actions-row {
  margin-top: 6%;
  background: #fff;
  padding-left: 9%;
  padding-top: 23px;
  padding-bottom: 14px;
}
.breadcrumbs-container svg {
  width: 10px;
}
.brdc-item {
  color: rgba(0, 0, 0, 0.7) !important;
}
.brdc-item:hover {
  text-decoration: none !important;
  color: #01223a !important;
}
.brdc-item.active-brdc-item {
  color: #01223a !important;
  font-weight: 500;
}
.ben-profile-container {
  margin-top: 3%;
}
.profile-infos-ben,
.wifes-infos-container,
.childs-infos-container {
  background: #ffffff;
  border-radius: 3px;
}
.wifes-infos-container {
  margin-bottom: 25px;
}
.wifes-infos-container,
.childs-infos-container {
  padding: 25px 20px;
}
.profile-infos-ben {
  padding: 18px 25px;
  margin-bottom: 20px;
}
.section-light-title {
  font-size: 16px;
  color: #0c0b0b;
}
.profile-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #c4c4c4;
}
.profile-avatar-container {
  text-align: center;
  margin: 40px 0;
}
.ben-name {
  font-size: 24px;
  color: #0c0b0b;
  text-transform: capitalize;
  font-weight: 500;
}
.ben-others-infos-container {
  display: flex;
  flex-direction: column;
}
.ben-others-infos-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  font-size: 16px;
}
.ben-others-infos-item-value {
  color: #0c0b0b;
  font-weight: 500;
}
.status-header-actions-container {
  display: flex;
  align-items: initial;
  justify-content: space-between;
  /* width: 100%; */
}
.header-actions-container {
  display: flex;
  width: 70%;
  justify-content: space-around;
}
.status-value {
  font-weight: 600;
}
/* .wifes-infos-container .adding-item-container {
  width: 30%;
} */
.easypm-modal-title {
  color: #0c0b0b;
  font-size: 18px;
}
.easypm-modal-header {
  border-bottom: none !important;
  padding-left: 25px;
  padding-right: 25px;
}
.easypm-modal-body {
  padding-left: 25px;
  padding-right: 25px;
}
.crud-form-group label {
  color: #0c0b0b;
  font-size: 13px;
}
.crud-form-control {
  padding: 22px;
  border-radius: 10px;
}
.input-radio-container {
  padding-top: 22px;
  padding-bottom: 60px;
}
.input-radio-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-radio-item {
  border: 0.5px solid #959595;
  padding: 13px;
  border-radius: 10px;
  width: 40%;
  display: flex;
}

input[type="radio"].input-radio-form-control {
  width: 20px;
  height: 20px;
}

span.input-radio-item-label {
  color: #515151;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.045em;
}
input[type="radio"].input-radio-form-control:checked:after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 3px;
  margin-right: 5px;
  background-clip: content-box;
  border: 1px solid #02a69c;
  background-color: #02a69c;
  border-radius: 50%;
}
input[type="radio"].input-radio-form-control:checked + label:before {
  background-color: #02a69c;
}
.crud-form-action-btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.crud-form-action-btn-container button {
  width: 45%;
  padding: 15px;
}
.crud-submit-btn {
  background: #02a69c !important;
  border-color: #02a69c !important;
  border-radius: 10px;
}
.crud-submit-btn:hover {
  color: #02a69c !important;
  background: transparent !important;
}
.cancelleb-btn:hover {
  border-radius: 10px;
  color: #000;
  font-weight: 600;
}
.cancelleb-btn {
  background: transparent;
  color: #515151;
  font-size: 17px;
  font-weight: 500;
}
.crud-row {
  padding-top: 10px;
  padding-bottom: 80px;
}
.crud-form-group {
  margin-bottom: 40px;
}
.nameFormatter {
  color: #000;
  font-weight: 500;
}
.nameFormatter:hover {
  text-decoration: none;
  color: #01223a !important;
}
.crud-form-interm {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.crud-form-group.crud-interm {
  width: 49%;
  position: relative;
}
.customy-label {
  display: inline-block;
  position: absolute;
  right: 4%;
  bottom: 14%;
  font-size: 18px;
  color: #8b8b8b;
}
.crud-col-item.pricing-cruded {
  padding: 15px 30px;
  background: #efefef;
  margin-left: 15px !important;
  margin-right: 15px !important;
  margin-bottom: 40px;
}
.taux-ipm-container,
.plafond-ipm-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.taux-ipm-label,
.plafond-ipm-label {
  color: #0c0b0b;
  font-weight: 400;
}
.taux-ipm-value,
.plafond-ipm-value {
  color: #0c0b0b;
  font-weight: 600;
}
input[type="date" i] {
  padding-right: 5px;
}
.soinsy,
.beny {
  top: 7%;
}
.header-hiddenable-section {
  position: absolute;
  right: 20%;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  padding: 18px;
  top: 90%;
  width: 23%;
}
.heder-hiddenable-title {
  color: #000000;
  padding: 7px;
  padding-left: 15px;
}
.header-hiddenable-ul li {
  list-style: none;
  padding: 12px 0;
  border-bottom: 1px solid #ececec;
  padding-left: 15px;
}
.header-hiddenable-ul {
  padding-left: 0;
}
.header-hiddenable-ul li:first-child {
  border-top: 1px solid #ececec;
}
.header-hiddenable-link {
  color: #000000;
  font-weight: 500;
}
.header-hiddenable-section:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
  border-bottom: 20px solid #ffffff;
  position: absolute;
  right: 20%;
  top: -6%;
}
.header-hiddenable-ul li:hover {
  background: rgb(2, 166, 156);
}
.header-hiddenable-ul li:hover .header-hiddenable-ul li a {
  /*     color: #fff !important; */
  text-decoration: none;
}

@keyframes grow {
  0% {
    transform: scale(3);
    opacity: 0;
  }
  50% {
    display: block;
    transform: scale(2);
  }
  100% {
    /* opacity: 1; */
    transform: scale(1);
  }
}
.header-hiddenable-section.animated {
  animation: grow 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
.header-hiddenable-notification-section {
  position: absolute;
  right: 7%;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  padding: 10px;
  top: 79%;
  width: 23%;
}
.header-hiddenable-notification-section:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
  border-bottom: 20px solid #ffffff;
  position: absolute;
  right: 45%;
  top: -7%;
}
.header-hiddenable-notification-title {
  padding: 12px 17px;
  border-bottom: 1px solid #ececec;
  margin-left: -15px;
  margin-right: -15px;
  color: #000000;
  font-weight: 500;
  font-size: 12px;
}
.header-hiddenable-notification-li {
  list-style: none;
  padding: 7px 15px;
  border-bottom: 1px solid #ececec;
}
.header-hiddenable-notification-ul {
  padding-left: 0;
  margin-left: -10px;
  margin-right: -10px;
}
.header-hiddenable-notification-li p {
  font-size: 12px;
  color: #000000;
  font-weight: 500;
}
.header-hiddenable-notification-li:last-child {
  border-bottom: none;
}
.header-hiddenable-notification-li span {
  color: #8a8989;
  font-size: 11px;
}
.header-hiddenable-notification-li:hover .notification-item-link p,
.header-hiddenable-notification-li:hover .notification-item-link,
.header-hiddenable-notification-li:hover .notification-item-link span {
  text-decoration: none !important;
  color: #02a69c !important;
}
.notification-item-link:visited,
.notification-item-link:active,
.notification-item-link:focus {
  text-decoration: none !important;
  /* color: #02A69C !important; */
}
.easypm-badge-success {
  background: #28a745 !important;
  padding: 5px 15px !important;
  width: auto !important;
  height: auto !important;
  font-size: 15px !important;
  border-radius: 50px;
}
.easypm-badge-danger {
  background: #dc3545 !important;
  padding: 5px 15px !important;
  width: auto !important;
  height: auto !important;
  font-size: 15px !important;
  border-radius: 50px;
}
.nameFormation-noLink {
  white-space: normal;
}
.easypm-tabs-nav-pills-item a.active {
  background: transparent !important;
  color: #02a69c !important;
  font-weight: 600;
  border-bottom: 2px solid #02a69c !important;
  border-radius: 0 !important;
}
.easypm-tabs-nav-pills-item a {
  color: #000;
  font-size: 15px;
  font-weight: 400;
}
.easypm-tabs-content {
  margin-top: 45px;
}
.easypm-tabs-item .crud-submit-btn {
  width: 100%;
}
.easypm-tabs-nav-pills-item a {
  padding-left: 0;
  margin-right: 35px;
  padding-right: 0;
}
.adding-col-offseting div {
  width: 100%;
}

input.form-control.crud-form-control.phone-edit.form-control {
  padding: 18.5px 14px 18.5px 60px !important;
  width: 100%;
}

.custom-control-label:before{
  border-color:#356FC2
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before{
  background-color:#356FC2;
  border-color:#356FC2
}