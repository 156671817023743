.page-title-dashboard {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #000000;

}
.color-list {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
}
.color-theme-list {
    color: #356FC2 !important;
    text-decoration: none !important;
}
.text-time-list {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.6);
}
#list-dashboard .list-group-item {
    border: none !important;
    padding: .75rem 0 !important;
}
#list-dashboard {
    box-shadow: 0px 0px 43px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    padding-bottom: 17rem !important;
}