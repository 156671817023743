.breadcrumb {
    background-color: transparent !important;
}
.breadcrumb-item {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.6);
}
.bloc-div {
    border: 2px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 10px;
    height: 59px;
}
.input-file {
    background: #F3F3F3;
    border: 0.25px solid #CECECE;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    overflow: hidden;
}
.label-input {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.5px;
    color: rgba(24, 24, 25, 0.9);
}
.first_label {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.025em;
    color: rgba(0, 0, 0, 0.5);
}
.second-label {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.025em;
    color: rgba(0, 0, 0, 0.5);
}
.btn-theme-plus {
    background: #356FC2;
    border-radius: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 1.5px;
    color: #FFFFFF;
    width: 100%;
    padding: 1rem;
}
.btn-theme-plus:hover {
    color: #356FC2;
    border: 1px solid #356FC2;
    background-color: #fff;
}
.nav-prev {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 13px;
    color: rgba(0, 0, 0, 0.6) !important;
}
.nav-prev:hover {
    text-decoration: none;
}
.adding-item-container {
    color: #0C0B0B !important;
}
.adding-item-container:hover {
    text-decoration: none;
}
.list-detail-point-interet {
    display: flex;
    justify-content: space-between;
}
.list-libele {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.5px;
    color: rgba(24, 24, 25, 0.9);
}
.list-contenu {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.7);
}
.list-btn {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #476DB6;

}
.list-marge-right {
    margin-right: 6rem;
}
.react-bs-table .react-bs-container-header>table>thead>tr>th .select-filter.placeholder-selected {
    background: #F7F7F7;
    border: none;
}

.select__control.css-1s2u09g-control {
    border: 2px solid #E1E1E1 !important;
    border-radius: 10px !important;
    height: 59px !important;
}

/* textarea {
    resize: none;
    overflow: hidden;
    min-height: 50px;
    max-height: 100px;
} */
