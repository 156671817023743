.icon-type-prestation {
  height: 50px;
  width: 66px;
  color: #356FC2;
}
.text-category {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  color: #0c0b0b;
  text-transform: none;
}
.category-prestation {
  flex-direction: column;
  align-items: center;
}
.bloc-img-prestation {
  background: #736767;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
}
.type_presta_image {
  width: 120px;
}
.shadow-category {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  border-radius: 10px;
  display: flex;
  align-items: center;
}
.sub-category-item {
  background: #2d9cdb;
  padding: 5px 15px;
  margin-right: 15px;
  border-radius: 50px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 7px;
}
.sub-category-item:hover {
  color: #fff !important;
}
.sub-categories-container.pl-3 {
  margin: 15px 0px;
}
.sous-titre-categorie {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}
.bloc-category-inline {
  display: inline-block;
}
.titre-category-prestation {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}
.bloc-liste {
  display: inline-block;
}
.bloc-icon {
  border: 1px solid #356FC2;
  border-radius: 50%;
  padding: 0.5rem;
  color: #c8c8c8;
  width: 40px;
  height: 40px;
  display: inline-block;
}
.input-add-category {
  background: #ffffff;
  border: 1px solid #ca8d19 !important;
  box-sizing: border-box;
  border-radius: 10px;
}
.icon-trash {
  position: absolute;
  top: 9px;
  right: 11px;
  color: #130f40;
}
.input-add-new-category {
  background: #f4f4f4;
  border-radius: 10px;
}
.icon-plus {
  color: #ca8d19;
  position: absolute;
  top: 11px;
  left: 6px;
}
.icon-image {
  left: 15px;
  position: absolute;
  top: 13px;
}
.rs-uploader-text .rs-uploader-trigger-btn {
  padding: 8px 61px;
  text-align: left !important;
}
.img-prestataire {
  border-radius: 50%;
  width: 120px;
  height: 120px;
}

.banner-position {
  background: #356FC2;
  padding: 5px 15px;
  margin-right: 15px;
  border-radius: 50px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 7px;
}

.banner-clic {
  background: tomato;
  padding: 5px 15px;
  margin-right: 15px;
  border-radius: 50px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 7px;
}
