.icon-add-user {
    color: #356FC2;
}
.text-btn-add {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}