.icon-like {
    color: #A5A8AD;
}
.like-nombre {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 0px;
    color: #A5A8AD;
}
.menu-bloc {
    display: flex;
    justify-content: space-between;
}
.name-profil-comment {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    color: #000000;
}
.date-profil-comment {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #65676B;
}
.dropdown-toggle::after {
    display: none;
}
.dropdown-menu-content-comment {
    left: -90px !important;
}
.list-menu-comment {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: #000000;

}
.list-menu-comment:hover {
    color: #fff;
    background-color: #033985;
}
.route-list {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.02em;
    color: #36373A;
}
.route-list-value {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: -0.02em;
    color: #000000;
}
.progress-trafic-width {
    width: 56.49px;
}
.statut-trafic {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: -0.02em;
    color: #000000;
}
.trafic-title {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #000000;

}
.progress-bar.sature{
    background: #EC0303;
}
.progress-bar.fluid{
    background: #069A77;
}
.progress-bar.moyen{
    background: #f3bc07;
}
.bg-fluid {
    background: #069A77 !important;
}
.bg-moyen {
    background: #f3bc07 !important;
}
.bg-sature {
    background: #EC0303 !important;
}
.text-comments-trafic {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.5px;
    color: rgba(24, 24, 25, 0.9);
}