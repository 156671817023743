.profile-avatar-container {
  width: 150px !important;
  height: 150px !important;
  overflow: hidden !important;
  position: relative !important;
  margin: 15px auto !important;
  border: 1px solid transparent !important;
  border-radius: 50% !important;
  cursor: pointer !important;
}

.profile-avatar-container img {
  width: 100% !important;
  height: 100% !important;
  display: block !important;
  object-fit: cover !important;
}

.profile-avatar-container span {
  position: absolute !important;
  bottom: -100% !important;
  left: 0 !important;
  width: 100% !important;
  height: 50% !important;
  background: #fff8 !important;
  text-align: center !important;
  font-weight: 400 !important;
  color: #356FC2 !important;
  transition: 0.3 ease-in-out !important;
}

.profile-avatar-container span p {
    color: #356FC2 !important;
}
.profile-avatar-container:hover span {
  bottom: -15% !important;
  
}

.profile-avatar-container span.charge {
  bottom: -15% !important;
}

.profile-avatar-container #file_up {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  opacity: 0 !important;
  cursor: pointer !important;
}
::-webkit-file-upload-button {
  cursor: pointer !important;
}
