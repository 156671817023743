.bloc-profil-contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nom-contact {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* color: #272C96; */
  color: #252733;
}

.nom-contact.active {
  color: #272c96;
}
.time-message-contact {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.01em;
  color: rgba(37, 39, 51, 0.5);
}
.contenu-message-contact {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: rgba(37, 39, 51, 0.8);
}
.notification-message-contact {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 11px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  background: #272c96;
  border-radius: 4px;
  padding: 0.5rem;
}
.bloc-message-contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profil-detail-message {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #252733;
}
.time-detail-message {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  letter-spacing: 0.01em;
  color: #90a0b7;
}
.bloc-message-recu {
  background: #272c96;
  width: 85%;
  border-radius: 10px;
}
.text-message-recu {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 165%;
  letter-spacing: 0.01em;
  color: #ffffff;
}
.bloc-message-envoyer {
  background: #ffffff;
  border: 1px solid #e4e9ee;
  box-sizing: border-box;
  width: 85%;
  float: right;
  border-radius: 10px;
}
.text-message-envoyer {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 165%;
  letter-spacing: 0.01em;
  /* color: rgba(37, 39, 51, 0.5); */
  color: #252733;
}
.bloc-details {
  width: 100%;
  padding: 40px 20px;
  min-height: 300px;
}
.input-send-messge {
  border: none;
  background: #fff;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #192a3e;
  /* opacity: 0.4; */
}
.image-profil-entete {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.img-expediteur {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.statut-expediteur {
  background: #2ed47a;
  border-radius: 50%;
  width: 6px;
  height: 6px;
}
.img-messge-contact {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.bloc-reponse {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.dropdown-menu.status-contact {
  left: -50px !important;
}
